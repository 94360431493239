<template>
<div id="wrapper">	
	<div id="termometer">
		<div id="temperature" style="height:0" data-value="0°C"></div>
		<div id="graduations"></div>
	</div>
	
	<div id="playground">		
		<div id="range">
			<input id="minTemp" type="text" lm35Templue="70">
		</div>
		<p id="unit">Celcius C°</p>
	</div>
	
</div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'TheTermometer',
  data() {
    return {
      units: {
        Celcius: '°C',
        Fahrenheit: '°F',
      },
      config: {
        minTemp: 0,
        maxTemp: 70,
        unit: 'Celcius',
      },
      lm35Temp: 0,
    };
  },
  computed: {
    temperatureHeight() {
      return ((this.lm35Temp - this.config.minTemp) / (this.config.maxTemp - this.config.minTemp)) * 100 + '%';
    },
    temperatureValue() {
      return this.lm35Temp + this.units[this.config.unit];
    },
    unit() {
      return this.config.unit === 'Celcius' ? 'C' : 'F';
    },
  },
  methods: {
    setTemperature() {
      $('#temperature').css('height', this.temperatureHeight);
      $('#temperature').attr('data-value', this.temperatureValue);
    },
    toggleUnit() {
      this.config.unit = this.config.unit === 'Celcius' ? 'Fahrenheit' : 'Celcius';
      this.setTemperature();
    },
  },
  mounted() {
    const self = this;

    const tempValueInputs = $('input[type="text"]');

    tempValueInputs.each(function () {
      $(this).change(function () {
        const newValue = $(this).val();

        if (isNaN(newValue)) {
          $(this).val(self.config[$(this).attr('id')]);
        } else {
          self.config[$(this).attr('id')] = $(this).val();
          self.setTemperature();
        }
      });
    });

    setInterval(function () {
      $.ajax({
        url: '../php/db_query_lm35.php',
        type: 'POST',
        dataType: 'json',
        success: function (result) {
          self.lm35Temp = result.celcius;
          self.setTemperature();
        }
      });
    }, 3000);

    $('#range').on('input', function () {
      self.setTemperature();
    });

    setTimeout(function () {
      self.setTemperature();
    }, 1000);
  },
};

</script>


<style scoped>
@import url("https://fonts.googleapis.com/css?family=Jaldi&display=swap");

#wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

p {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#info {
  opacity: 0.2;
  margin: 0;
  text-align: center;
}

#termometer {
  width: 25px;
  background: #38383f;
  height: 240px;
  position: relative;
  border: 9px solid #2a2a2e;
  border-radius: 20px;
  z-index: 1;
  margin-bottom: 50px;
}
#termometer:before, #termometer:after {
  position: absolute;
  content: "";
  border-radius: 50%;
}
#termometer:before {
  width: 100%;
  height: 34px;
  bottom: 9px;
  background: #38383f;
  z-index: -1;
}
#termometer:after {
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  background-color: #3dcadf;
  bottom: -41px;
  border: 9px solid #2a2a2e;
  z-index: -3;
  left: 50%;
}
#termometer #graduations {
  height: 59%;
  top: 20%;
  width: 50%;
}
#termometer #graduations, #termometer #graduations:before {
  position: absolute;
  border-top: 2px solid rgba(0, 0, 0, 0.5);
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}
#termometer #graduations:before {
  content: "";
  height: 34%;
  width: 100%;
  top: 32%;
}
#termometer #temperature {
  bottom: 0;
  background: linear-gradient(#f17a65, #3dcadf) no-repeat bottom;
  width: 100%;
  border-radius: 20px;
  background-size: 100% 240px;
  transition: all 0.2s ease-in-out;
}
#termometer #temperature, #termometer #temperature:before, #termometer #temperature:after {
  position: absolute;
}
#termometer #temperature:before {
  content: attr(data-value);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 2;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1em;
  line-height: 1;
  transform: translateY(50%);
  left: calc(100% + 1em / 1.5);
  top: calc(-1em + 5px - 5px * 2);
}
#termometer #temperature:after {
  content: "";
  border-top: 0.4545454545em solid transparent;
  border-bottom: 0.4545454545em solid transparent;
  border-right: 0.6666666667em solid rgba(0, 0, 0, 0.7);
  left: 100%;
  top: calc(-1em / 2.2 + 5px);
}

#playground {
  font-size: 1.1em;
}
#playground #range {
  display: flex;
}
#playground #range input[type=text] {
  width: 2em;
  background: transparent;
  border: none;
  color: inherit;
  font: inherit;
  margin: 0 5px;
  padding: 0px 5px;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-in-out;
}
#playground #range input[type=text]:focus {
  border-color: #3dcadf;
  outline: none;
}
#playground #range input[type=text]:first-child {
  text-align: right;
}
#playground #unit {
  width: 100%;
  margin: 0;
  text-align: center;
}
#playground #unit:hover {
  cursor: pointer;
}

input[type=range] {
  -webkit-appearance: none;
  background: transparent;
  margin: 5.5px 0;
  width: 100%;
}
input[type=range]::-moz-focus-outer {
  border: 0;
}
input[type=range]:hover {
  cursor: pointer;
}
input[type=range]:focus {
  outline: 0;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #313137;
  border-color: #313137;
}
input[type=range]:focus::-ms-fill-lower {
  background: #2a2a2e;
}
input[type=range]:focus::-ms-fill-upper {
  background: #313137;
  border-color: #313137;
}
input[type=range]::-webkit-slider-runnable-track {
  cursor: default;
  height: 10px;
  transition: all 0.2s ease;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 1px 1px 1px transparent, 0 0 1px rgba(13, 13, 13, 0);
  background: #2a2a2e;
  border: 2px solid #2a2a2e;
  border-radius: 5px;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 4px 4px 4px transparent, 0 0 4px rgba(13, 13, 13, 0);
  background: #3dcadf;
  border: 0px solid #3d3d44;
  border-radius: 12px;
  cursor: pointer;
  height: 11px;
  width: 18px;
  -webkit-appearance: none;
  margin-top: -2.5px;
}
input[type=range]::-moz-range-track {
  box-shadow: 1px 1px 1px transparent, 0 0 1px rgba(13, 13, 13, 0);
  cursor: default;
  height: 10px;
  transition: all 0.2s ease;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: #2a2a2e;
  border: 2px solid #2a2a2e;
  border-radius: 5px;
  height: 5px;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 4px 4px 4px transparent, 0 0 4px rgba(13, 13, 13, 0);
  background: #3dcadf;
  border: 0px solid #3d3d44;
  border-radius: 12px;
  cursor: pointer;
  height: 7px;
  width: 14px;
}
input[type=range]::-ms-track {
  cursor: default;
  height: 10px;
  transition: all 0.2s ease;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: transparent;
  border-color: transparent;
  border-width: 5.5px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  box-shadow: 1px 1px 1px transparent, 0 0 1px rgba(13, 13, 13, 0);
  background: #222226;
  border: 2px solid #2a2a2e;
  border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
  box-shadow: 1px 1px 1px transparent, 0 0 1px rgba(13, 13, 13, 0);
  background: #2a2a2e;
  border: 2px solid #2a2a2e;
  border-radius: 10px;
}
input[type=range]::-ms-thumb {
  box-shadow: 4px 4px 4px transparent, 0 0 4px rgba(13, 13, 13, 0);
  background: #3dcadf;
  border: 0px solid #3d3d44;
  border-radius: 12px;
  cursor: pointer;
  height: 7px;
  width: 14px;
  margin-top: 2.5px;
}
input[type=range]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}
input[type=range]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}
input[type=range]:disabled::-ms-thumb {
  cursor: not-allowed;
}
input[type=range]:disabled::-webkit-slider-runnable-track {
  cursor: not-allowed;
}
input[type=range]:disabled::-ms-fill-lower {
  cursor: not-allowed;
}
input[type=range]:disabled::-ms-fill-upper {
  cursor: not-allowed;
}
</style>